<template>
  <DataTable
    ref="dataTable"
    v-model:first="currentPageOffset"
    :value="products"
    class="card-table spaced"
    :class="{ loading: loading, 'public-issuer': publicIssuerProduct }"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="itemsPerPage"
    removableSort
    stateKey="Products.tableState"
    responsiveLayout="scroll"
    sortField="id"
    :sortOrder="-1"
    :lazy="loading"
    @row-click="onRowClick"
  >
    <ColumnGroup type="header">
      <Row>
        <Column field="longName" :header="columnProps?.header" :headerStyle="columnProps?.titleStyle" sortable />
        <Column field="isin" header="Isin" sortable v-if="publicIssuerProduct" />
        <Column field="valor" header="Valor" sortable v-if="publicIssuerProduct" />
        <Column field="valuation" header="Valuation" :headerStyle="columnProps?.valuationStyle" sortable />
        <!-- <Column field="grossAssets" header="NET Assets" sortable v-if="showGrossAssets" /> -->
        <Column field="issuerName" header="Issuer" :headerStyle="columnProps?.issuerStyle" sortable v-if="showIssuer" />
        <!-- <Column
          field="terminatedAt"
          header="Term. Date"
          headerClass="min-width"
          sortable
          v-if="!publicIssuerProduct"
        /> -->
        <Column field="valuationDate" header="Valuation Date" sortable v-if="publicIssuerProduct" />
        <Column
          field="currentWorkflows"
          header="Active WF"
          :headerStyle="columnProps?.activeWfStyle"
          sortable
          v-if="showWorkflows"
        />
        <Column field="productStateId" header="Status" :headerClass="columnProps?.statusClass" sortable />
      </Row>
    </ColumnGroup>
    <Column field="longName">
      <template #body="slotProps">
        <router-link :to="getProductRouteParams(slotProps.data)" class="row-link" />
        <div
          class="title p-d-flex title-container"
          v-if="!loading"
          :class="{ 'p-mb-3': !publicIssuerProduct }"
        >
          <div class="p-d-flex p-flex-column tag-container">
            <Tag class="p-tag-inverted p-text-uppercase" :value="slotProps.data.currencyIso" v-if="slotProps.data.currencyIso" />
            <Tag
              class="p-tag-inverted p-text-uppercase"
              value="ISP"
              v-if="isUserGentwo && slotProps.data?.productBelongsToIsp"
            />
            <Tag
              v-tooltip="'This product is promoted on client dashboards'"
              v-if="slotProps.data.isVisibleOnDashboard && getShowcasedProduct(slotProps.data)"
              class="p-relative promoted-icon p-tag-inverted p-text-uppercase"
            >
              <icon data="@icon/loudspeaker.svg" width="1.5em" height="1.5em" class="p-mr-2" />
            </Tag>
          </div>
          <div class="p-d-flex p-flex-column product-info">
            <div class="p-d-flex p-align-center product-name">
              <i class="pi pi-arrow-right-arrow-left pi-icon-pink p-mr-2 p-mt-1" v-if="isProductMigrated(slotProps.data)" />
              <span>{{ slotProps.data.longName }}</span>
            </div>
            <div class="p-d-flex p-flex-column product-details" v-if="!publicIssuerProduct">
              <KVCard
                label="ISIN"
                inline
                fullWidth
                contentAlign="middle"
                :loading="loading"
                :class="{ 'p-mb-1': loading }"
              >
                <strong>{{ slotProps.data.isin }}</strong>
              </KVCard>
              <KVCard
                label="Valor"
                inline
                fullWidth
                contentAlign="middle"
                :loading="loading"
                v-if="slotProps.data.valor"
              >
                <strong>{{ slotProps.data.valor }}</strong>
              </KVCard>
            </div>
            <Skeleton v-if="loading" :width="`${50 + Math.random() * 40}%`" height="1.5em" />
          </div>
        </div>
      </template>
    </Column>
    <Column field="isin" v-if="publicIssuerProduct">
      <template #body="slotProps">
        <strong>{{ slotProps.data.isin }}</strong>
      </template>
    </Column>
    <Column field="valor" v-if="publicIssuerProduct">
      <template #body="slotProps">
        <strong>{{ slotProps.data.valor }}</strong>
      </template>
    </Column>
    <!-- <Column field="internalProductTypeId">
      <template #body="slotProps">
        <div class="p-d-flex p-mb-1">
          <Tag
            class="product-type p-mr-2"
            :value="getProductType(slotProps.data.internalProductTypeId)"
            :style="{
              background: getProductTypeColor(slotProps.data.internalProductTypeId),
              color: 'var(--product-type-color)',
            }"
            v-if="slotProps.data.internalProductTypeId"
          />
          <Skeleton v-if="loading" width="2.5em" height="1.5em" class="p-mr-2 p-mb-1" />
          <Tag
            class="p-tag-outlined"
            :value="slotProps.data.currencyIso"
            v-if="slotProps.data.currencyIso"
          />
          <Skeleton v-if="loading" width="2.5em" height="1.5em" />
        </div>
      </template>
    </Column> -->
    <Column field="valuation">
      <template #body="slotProps">
          <strong>
            <Valuation
              :value="slotProps.data.latestProductValuation?.price"
              :date="slotProps.data.latestProductValuation?.priceDateTime"
              :currencyIso="slotProps.data.currencyIso"
              :showDate="false"
              :loading="loading"
              :key="slotProps.data.id"
              :valuationTypeId="slotProps.data.valuationTypeId"
            />
          </strong>
          <div class="p-d-flex p-flex-column product-details p-mt-3" v-if="!publicIssuerProduct">
            <KVCard 
              label="Gross Assets" 
              contentAlign="middle" 
              inline 
              fullWidth 
              :loading="loading"
            >
              <strong> {{ slotProps.data?.grossAssets ? slotProps.data?.grossAssets : 'N/A' }}</strong>
            </KVCard>
            <KVCard
              label="Outs.
              Cert."
              contentAlign="middle"
              inline
              fullWidth
              :loading="loading"
            >
              <strong> {{ slotProps.data?.latestOutstandingCertificateValue ? slotProps.data?.latestOutstandingCertificateValue : 'N/A'}}</strong>
            </KVCard>
          </div>
      </template>
    </Column>
    <Column field="valuationDate" v-if="publicIssuerProduct">
      <template #body="slotProps">
        <Date>
          {{ slotProps.data.latestProductValuation?.priceDateTime }}
        </Date>
      </template>
    </Column>
    <!-- <Column field="grossAssets" v-if="showGrossAssets">
      <template #body="slotProps">
        <strong>
          <CurrencyValue :currency="slotProps.data.currencyIso" v-if="slotProps.data.grossAssets">
            {{ slotProps.data.grossAssets }}
          </CurrencyValue>
        </strong>
        <div
          v-if="slotProps.data.latestOutstandingCertificateValue"
          :key="slotProps.data.id"
          class="p-mt-3"
        >
          <KVCard label="Outst. Cert." inline>
            <strong> {{ slotProps.data.latestOutstandingCertificateValue }}</strong>
          </KVCard>
        </div>
      </template>
    </Column> -->
    <Column field="issuerName" v-if="showIssuer">
      <template #body="slotProps">
        <strong>{{ slotProps.data.issuerName }}</strong>
        <Skeleton
          v-if="loading"
          :width="`${50 + Math.random() * 40}%`"
          height="1.25em"
          class="p-mb-1"
        />
        <br />
        <div class="p-d-flex p-flex-column product-details p-mt-3">
          <KVCard 
            label="Issue date" 
            inline
            fullWidth
            contentAlign="middle"
            :loading="loading">
            <Date :key="slotProps.data.id">{{ slotProps.data.paymentDate }}</Date>
          </KVCard>
        </div>
      </template>
    </Column>
    <Column field="paymentDate" v-if="!showIssuer && !publicIssuerProduct">
      <template #body="slotProps">
        <Date :key="slotProps.data.id">{{ slotProps.data.paymentDate }}</Date>
        <Skeleton v-if="loading" width="10ch" height="1.25em" />
      </template>
    </Column>
    <!-- <Column field="terminatedAt" v-if="!publicIssuerProduct">
      <template #body="slotProps">
        <Date :key="slotProps.data.id" v-if="slotProps.data.terminatedAt">
          {{ slotProps.data.terminatedAt }}
        </Date>
        <CurrencyValue
          :currency="slotProps.data.currencyIso"
          v-if="Number.isFinite(slotProps.data.terminationPrice)"
        >
          {{ slotProps.data.terminationPrice }}
        </CurrencyValue>
      </template>
    </Column> -->
    <Column field="currentWorkflows" v-if="showWorkflows">
      <template #body="slotProps">
        <WorkflowsInProgress :workflows="slotProps.data.currentWorkflows" collapsed />
      </template>
    </Column>
    <Column field="productStateId">
      <template #body="slotProps">
        <Status
          v-tooltip.bottom="slotProps.data.productState?.externalName"
          :value="slotProps.data.productState?.id"
          :label="slotProps.data.productState?.externalName"
          v-if="loading || (!loading && slotProps.data.productState && !publicIssuerProduct)"
          wrap
        />
        <strong v-else>{{ slotProps.data.productState.name }}</strong>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import commaNumber from "comma-number";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Tag from "primevue/tag";
import Skeleton from "primevue/skeleton";
import KVCard from "@/components/elements/KeyValueCard";
import Status from "@/components/elements/Status";
import WorkflowsInProgress from "@/components/elements/WorkflowsInProgress";
import Date from "@/components/elements/Date";
import Valuation from "@/components/elements/Valuation";
import CurrencyValue from "@/components/elements/CurrencyValue";
import productMixins from "@/mixins/product";
import { useModelWrapper } from "@/utils/modelWrapper";
import Tooltip from "primevue/tooltip";
import { ValuationTypeEnum, PartyType, WellKnownPartyIds } from "@/components/product/Types";
import product from "@/mixins/product";
export default {
  name: "ProductTable",
  emits: ["update:first"],
  mixins: [productMixins],
  directives: {
    tooltip: Tooltip,
  },
  setup(props, { emit }) {
    const currentPageOffset = useModelWrapper(props, emit, "first");
    return {
      currentPageOffset,
    };
  },
  components: {
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Status,
    WorkflowsInProgress,
    Date,
    KVCard,
    Tag,
    Valuation,
    Skeleton,
    CurrencyValue,
  },
  props: {
    data: Array,
    loading: Boolean,
    itemsPerPage: Number,
    first: Number,
    showWorkflows: {
      type: Boolean,
      default: true,
    },
    showIssuer: {
      type: Boolean,
      default: true,
    },
    showGrossAssets: {
      type: Boolean,
      default: true,
    },
    publicIssuerProduct: {
      type: Boolean,
      default: false,
    },
    isUserGentwo: Boolean,
  },
  computed: {
    products() {
      return this.data?.map(product => ({
        ...product,
        valuation: product.latestProductValuation?.price,
        latestOutstandingCertificateValue:
          Number.isFinite(product.latestOutstandingCertificate?.value) &&
          commaNumber(product.latestOutstandingCertificate.value, "'"),
        grossAssets:
          product.latestOutstandingCertificate?.value &&
          product.latestProductValuation?.price &&
          commaNumber(((product.latestOutstandingCertificate.value * product.latestProductValuation.price) /
            (product.valuationTypeId === ValuationTypeEnum.Percentage ? 100 : 1)).toFixed(2), "'"),
      }));
    },
    columnProps(){
      return{
        header: this.publicIssuerProduct ? 'Title' : 'Product Name',
        titleStyle: !this.publicIssuerProduct && 'width: 30%; padding-left: 6rem;',
        valuationStyle: !this.publicIssuerProduct && 'width: 20%',
        issuerStyle: !this.publicIssuerProduct && 'width: 20%',
        activeWfStyle: !this.publicIssuerProduct && 'width: 10%; text-align: center',
        statusClass: !this.publicIssuerProduct && 'min-width'
      }
    }
  },
  mounted() {
    if (!this.loading) {
      // This must be updated directly because the state is loaded beforeMount
      this.$refs.dataTable.d_first = this.first;
    }
  },
  methods: {
    onRowClick(event) {
      this.$router.push(this.getProductRouteParams(event.data));
    },
    getProductRouteParams({ isin }) {
      return isin ? { name: "Product", params: { isin: isin } } : "#";
    },
    getShowcasedProduct(product) {
      return (
        product.productState?.externalName === "Issued" ||
        product.productState?.externalName === "Open for subscriptions"
      );
    },
    isProductMigrated(product) {
      return (product?.productParties ?? []).find(party => party.partyTypeId === PartyType.DIRECTOR_OF_ISSUER && party.id === WellKnownPartyIds.CSC);
    }, 
  },
};
</script>

<style scoped lang="scss">
.card-table {
  :deep() {
    tbody tr {
      cursor: pointer;
      transition: all 0.15s ease;
      transform: scale(1) translateZ(0);
      &:hover,
      &:focus {
        background-color: var(--table-bg);
        opacity: 1;

        & > td {
          background-color: var(--card-hover-bg) !important;
        }
      }
      &:active {
        transform: scale(0.99) translateZ(0);
      }
    }
    .status{
    z-index: 1000;
      .p-tag{
        width: 14ch;
      }
    }
  }
  &:not(.public-issuer):deep(){
    tbody > tr { 
      td{
        vertical-align: top;
      }
      th:nth-last-child(2), td:nth-last-child(2){
        text-align: center;
      }
    }
  }
  .title {
    display: block;
    font-size: 1.167em;
    font-weight: 600;
    line-height: 1.1;
    letter-spacing: -0.03rem;
  }
  .product-type {
    border: 1px solid transparent;
    font-weight: 600;
  }
  .promoted-icon {
    z-index: 10;
  }
  .title-container {
    gap: 1.67rem;
  }
  .tag-container, .product-name {
    gap: 0.5rem;
  }
  .product-info {
    width: 100%;
    gap: 1.34rem;
  }
  .product-details {
    gap: 0.67rem;
  }
  .title{
    :deep(){
      .p-card .p-card-caption{
        flex: 0 0 15%;
      }
    }
  }
}
</style>
